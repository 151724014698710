<template>
    <div>
        <b-tabs content-class="mt-3" pills>
            <template v-if="allowedBackground">
                <b-tab
                    v-for="(item, index) in backgrounds"
                    v-bind:key="'background-category-' + index"
                    :title="item.name"
                >
                    <ul class="item-list" id="icon-select">
                        <li
                            v-for="(sItem, sIndex) in item.backgrounds"
                            v-bind:key="
                                'background-item-' + index + '-' + sIndex
                            "
                        >
                            <input
                                type="radio"
                                name="icon"
                                v-model="form.background"
                                :value="sItem.id"
                                :id="'icon-background-' + index + '-' + sIndex"
                            />
                            <label
                                :for="'icon-background-' + index + '-' + sIndex"
                                class="d-block"
                                v-on:click="setBackground"
                                ><img :src="sItem.image" :alt="sItem.name" />
                            </label>
                        </li>
                    </ul>
                </b-tab>
            </template>
            <b-tab :title="$t('Színek')">
                <div class="creator-title">
                    {{ $t("Válassz háttér színt") }}:
                </div>

                <ul class="color-rounds" id="bgColorSwitcher">
                    <li
                        v-for="(item, index) in colors"
                        v-bind:key="'text-color-' + index"
                    >
                        <input
                            type="radio"
                            name="icon"
                            :id="'bg-color-' + item.id"
                            v-model="form.backgroundColor"
                            :value="item.hexa"
                        />
                        <label
                            :for="'bg-color-' + item.id"
                            class="d-block"
                            v-on:click="setColor"
                        >
                            <div
                                class="round"
                                :data-color="item.hexa"
                                :style="'background-color: ' + item.hexa"
                            ></div>
                        </label>
                    </li>
                </ul>
            </b-tab>
            <b-tab :title="$t('Egyedi háttér')" v-if="allowedBackground">
                <div class="creator-title">
                    {{ $t("Egyedi háttér feltöltése") }}:
                </div>
                <input
                    type="file"
                    ref="file2"
                    name="custom-icon"
                    class="form-control"
                    id="custom-icon-file-background"
                    v-on:change="handleFileUpload()"
                    accept="image/*"
                    style="display: none"
                />
                <button
                    class="btn btn-simple upload"
                    v-on:click="triggerUpload"
                >
                    {{ $t("Fájl feltöltés") }}
                </button>
                <cropper
                    classname="cropper"
                    v-if="preview"
                    :src="preview"
                    ref="backgroundCropper"
                    :stencilProps="{
                        aspectRatio: width / height
                    }"
                    @change="change"
                ></cropper>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
    name: "TabBackground",
    props: [
        "sticker",
        "backgrounds",
        "colors",
        "allowedBackground",
        "height",
        "width"
    ],
    components: { Cropper },
    data() {
        return {
            form: {
                background: null,
                customFile: null,
                backgroundColor: null
            },
            preview: null
        };
    },
    watch: {
        form: {
            handler: function(val) {
                this.$emit("updateValues", val);
            },
            deep: true
        }
    },
    methods: {
        triggerUpload() {
            $("#custom-icon-file-background").click();
        },
        change({ coordinates, canvas }) {
            console.log(coordinates, canvas);
            const self = this;
            self.form.background = null;
            canvas.toBlob(blob => {
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function() {
                    var base64data = reader.result;
                    self.$emit("imageCropped", base64data);
                    self.form.customFileUrl = base64data;
                };
            });
        },
        setBackground() {
            this.form.customFile = null;
            this.form.backgroundColor = null;
            $("#custom-icon-file-background").val("");
            this.preview = null;
        },
        setColor() {
            this.form.customFile = null;
            $("#custom-icon-file-background").val("");
            this.preview = null;
            this.form.background = null;
        },
        handleFileUpload() {
            this.form.background = null;
            this.form.backgroundColor = null;
            this.form.customFile = this.$refs.file2.files[0];
            this.preview = URL.createObjectURL(this.$refs.file2.files[0]);
        }
    }
};
</script>

<style scoped></style>
