<template>
    <div v-html="sticker.faq">
        <!--<div class="h3">Kérdés</div>
        <p>
            Válasz Vores klistermærker sidder godt fast på de fleste materialer.
            Mærkerne kan benyttes til mærkning af både tøj og genstande. Ved
            mærkning af tøj, anbefaler vi at de sættes på vaskeanvisningen. For
            tøj uden vaskeanvisning, anbefaler vi at der istedet benyttes
            strygemærker.
        </p>
        <div class="h3">Kérdés</div>
        <p>
            Válasz Vores klistermærker sidder godt fast på de fleste materialer.
            Mærkerne kan benyttes til mærkning af både tøj og genstande. Ved
            mærkning af tøj, anbefaler vi at de sættes på vaskeanvisningen. For
            tøj uden vaskeanvisning, anbefaler vi at der istedet benyttes
            strygemærker.
        </p>
        <div class="h3">Kérdés</div>
        <p>
            Válasz Vores klistermærker sidder godt fast på de fleste materialer.
            Mærkerne kan benyttes til mærkning af både tøj og genstande. Ved
            mærkning af tøj, anbefaler vi at de sættes på vaskeanvisningen. For
            tøj uden vaskeanvisning, anbefaler vi at der istedet benyttes
            strygemærker.
        </p>
        <div class="h3">Kérdés</div>
        <p>
            Válasz Vores klistermærker sidder godt fast på de fleste materialer.
            Mærkerne kan benyttes til mærkning af både tøj og genstande. Ved
            mærkning af tøj, anbefaler vi at de sættes på vaskeanvisningen. For
            tøj uden vaskeanvisning, anbefaler vi at der istedet benyttes
            strygemærker.
        </p>
        <div class="h3">Kérdés</div>
        <p>
            Válasz Vores klistermærker sidder godt fast på de fleste materialer.
            Mærkerne kan benyttes til mærkning af både tøj og genstande. Ved
            mærkning af tøj, anbefaler vi at de sættes på vaskeanvisningen. For
            tøj uden vaskeanvisning, anbefaler vi at der istedet benyttes
            strygemærker.
        </p>
        <div class="h3">Kérdés</div>
        <p>
            Válasz Vores klistermærker sidder godt fast på de fleste materialer.
            Mærkerne kan benyttes til mærkning af både tøj og genstande. Ved
            mærkning af tøj, anbefaler vi at de sættes på vaskeanvisningen. For
            tøj uden vaskeanvisning, anbefaler vi at der istedet benyttes
            strygemærker.
        </p>-->
    </div>
</template>

<script>
export default {
    name: "TabFaq",
    props: ["sticker"]
};
</script>

<style scoped></style>
