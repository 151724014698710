<template>
    <div class="row">
        <div class="col-md-8" v-html="sticker.product_info"></div>
        <!--<div class="col-md-8">
            <div class="h3">
                Lorem ipsum dolor sit amet, consectetuer adipiscing
            </div>
            <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis.
            </p>

            <div class="h3 mt-5">
                Lorem ipsum dolor sit amet, consectetuer adipiscing
            </div>
            <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis.
            </p>
        </div>-->
        <div class="col-md-4">
            <ul class="benefits">
                <li>
                    <img src="img/icon1.svg" class="mini-icon" />100%
                    Termékgarancia
                </li>
                <li><img src="img/icon2.svg" class="mini-icon" />Strapabíró</li>
                <li><img src="img/icon3.svg" class="mini-icon" />Vízálló</li>
                <li><img src="img/icon4.svg" class="mini-icon" />Színes</li>
                <li>
                    <img src="img/icon5.svg" class="mini-icon" />Ingyenes
                    szállítás
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "TabProductInfo",
    props: ["sticker"]
};
</script>

<style scoped></style>
