<template>
    <div v-html="sticker.reviews"></div>
</template>

<script>
export default {
    name: "TabReviews",
    props: ["sticker"]
};
</script>

<style scoped></style>
