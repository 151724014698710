<template>
    <div>
        <div class="row">
            <div class="col">
                <p class="my-4" v-html="selectedSticker.short_description"></p>
            </div>
        </div>
        <div
            v-if="parseInt(selectedSticker.textile) === 1"
            class="row align-items-center my-4 pb-4 border-bottom"
            id="textile"
        >
            <div class="col-12 col-xl">
                <strong>{{ $t("Textil felületekre") }}</strong>
                <div class="mb-3">
                    <small v-html="selectedSticker.textile_description"></small>
                </div>
                <div class="mb-3">
                    <small>{{ $t("30 db / csomag") }}</small>
                </div>
            </div>
            <div class="col-8 col-xl-auto d-flex align-items-center">
                <div class="number-input-holder">
                    <button
                        class="btn btn-simple sub"
                        v-on:click="removeQty('qty_textile')"
                    >
                        -
                    </button>
                    <div class="product-quantity">
                        <input
                            class="form-control"
                            type="number"
                            v-model="form.qty_textile"
                            value="0"
                            min="0"
                        />
                    </div>
                    <button
                        class="btn btn-simple add"
                        v-on:click="addQty('qty_textile')"
                    >
                        +
                    </button>
                </div>
                <div class="mx-3">
                    {{ $t("csomag") }}
                </div>
            </div>
            <div class="col-4 col-xl-auto text-end">
                <strong class="price"
                    >{{ selectedSticker.price_textile }} Ft</strong
                >
            </div>
        </div>
        <div
            v-if="parseInt(selectedSticker.plastic) === 1"
            class="row align-items-center my-4 pb-4 border-bottom"
            id="plastic"
        >
            <div class="col-12 col-xl">
                <strong>{{ $t("Műanyag felületekre") }}</strong>
                <div class="mb-3">
                    <small v-html="selectedSticker.plastic_description"></small>
                </div>
                <div class="mb-3">
                    <small>{{ $t("30 db / csomag") }}</small>
                </div>
            </div>
            <div class="col-8 col-xl-auto d-flex align-items-center">
                <div class="number-input-holder">
                    <button
                        class="btn btn-simple sub"
                        v-on:click="removeQty('qty_plastic')"
                    >
                        -
                    </button>
                    <div class="product-quantity">
                        <input
                            class="form-control"
                            type="number"
                            v-model="form.qty_plastic"
                            value="0"
                            min="0"
                        />
                    </div>
                    <button
                        class="btn btn-simple add"
                        v-on:click="addQty('qty_plastic')"
                    >
                        +
                    </button>
                </div>
                <div class="mx-3">
                    {{ $t("csomag") }}
                </div>
            </div>
            <div class="col-4 col-xl-auto text-end">
                <strong class="price"
                    >{{ selectedSticker.price_plastic }} Ft</strong
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DownSide",
    props: ["model", "stickers"],
    data() {
        return {
            form: {
                qty_textile: 0,
                qty_plastic: 0
            }
        };
    },
    watch: {
        form: {
            handler: function(val) {
                this.$emit("updateValues", val);
            },
            deep: true
        },
        model: {
            handler: function(val) {
                if (val.qty_plastic !== this.form.qty_plastic) {
                    this.form.qty_plastic = val.qty_plastic;
                }
                if (val.qty_textile !== this.form.qty_textile) {
                    this.form.qty_textile = val.qty_textile;
                }
            },
            deep: true
        }
    },
    computed: {
        selectedSticker() {
            return this.stickers.find(
                element => element.sku === this.model.sticker
            );
        }
    },
    methods: {
        removeQty(type) {
            if (this.form[type] > 0) {
                this.form[type] -= 1;
            }
        },
        addQty(type) {
            this.form[type] += 1;
        }
    }
};
</script>

<style scoped></style>
