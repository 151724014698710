<template>
    <div>
        <b-tabs content-class="mt-3" pills>
            <b-tab
                v-for="(item, index) in samples"
                v-bind:key="'sample-category-' + index"
                :title="item.name"
            >
                <ul class="item-list" id="icon-select">
                    <li
                        v-for="(sItem, sIndex) in item.samples"
                        v-bind:key="'sample-item-' + index + '-' + sIndex"
                    >
                        <input
                            type="radio"
                            name="icon"
                            v-model="form.sample"
                            :value="sItem.id"
                            :id="'icon-sample-' + index + '-' + sIndex"
                        />
                        <label
                            :for="'icon-sample-' + index + '-' + sIndex"
                            class="d-block"
                            v-on:click="setSample"
                            ><img :src="sItem.image" :alt="sItem.name" />
                        </label>
                    </li>
                </ul>
            </b-tab>
            <b-tab :title="$t('Egyedi minta')">
                <div class="creator-title">
                    {{ $t("Egyedi minta feltöltése") }}:
                </div>
                <input
                    type="file"
                    ref="file1"
                    v-on:change="handleFileUpload()"
                    name="custom-icon"
                    class="form-control"
                    accept="image/*"
                    id="custom-icon-file-sample"
                    style="display: none"
                />
                <button
                    class="btn btn-simple upload"
                    v-on:click="triggerUpload"
                >
                    {{ $t("Fájl feltöltés") }}
                </button>
                <cropper
                    classname="cropper"
                    v-if="preview"
                    :src="preview"
                    ref="sampleCropper"
                    :stencilProps="{
                        aspectRatio: 4 / 5
                    }"
                    @change="change"
                ></cropper>
            </b-tab>
        </b-tabs>
    </div>

</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
    name: "TabSample",
    props: ["sticker", "samples", "height", "width"],
    components: {
        Cropper
    },
    data() {
        return {
            form: {
                sample: null,
                customFile: null
            },
            preview: null
        };
    },
    watch: {
        form: {
            handler: function(val) {
                this.$emit("updateValues", val);
            },
            deep: true
        }
    },
    methods: {
        triggerUpload() {
            $("#custom-icon-file-sample").click();
        },
        change({ coordinates, canvas }) {
            console.log(coordinates, canvas);
            const self = this;
            self.form.sample = null;
            canvas.toBlob(blob => {
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function() {
                    var base64data = reader.result;
                    self.$emit("imageCropped", base64data);
                    self.form.customFileUrl = base64data;
                };
            });
        },
        setSample() {
            this.form.customFile = null;
            $("#custom-icon-file-sample").val("");
            this.preview = null;
        },
        handleFileUpload() {
            this.form.sample = null;
            this.form.customFile = this.$refs.file1.files[0];
            this.preview = URL.createObjectURL(this.$refs.file1.files[0]);
        },
        defaultSize() {
            return {
                width: 300,
                height: 300
            };
        }
    }
};
</script>

<style>
.vue-advanced-cropper__background {
    background: white !important;
}
.vue-advanced-cropper__foreground {
    background: #ccc !important;
}
</style>
