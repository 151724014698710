var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6 col-xl-4"},[_c('div',{staticClass:"sticky-top pt-5"},[_c('h1',{staticClass:"h4"},[_c('strong',[_vm._v(_vm._s(_vm.$t("Matricatervező")))])]),_c('div',{staticClass:"form-floating mb-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.selectedSticker),expression:"form.selectedSticker"}],staticClass:"form-select",attrs:{"id":"sticker_size","data-currency":"Ft","aria-label":"Floating label select example"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "selectedSticker", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.stickers),function(item,index){return _c('option',{key:'sticker-' + index,attrs:{"data-price-textile":item.price_textile,"data-price-plastic":item.price_plastic},domProps:{"value":item.sku}},[_vm._v(_vm._s(item.name))])}),0),_c('label',{attrs:{"for":"sticker_size"}},[_vm._v(_vm._s(_vm.$t("Válassz matrica típust")))])]),(
                parseInt(_vm.compSelectedSticker.background) === 1 ||
                    parseInt(_vm.compSelectedSticker.figure) === 1
            )?_c('div',{staticClass:"preview-holder my-4"},[_c('div',{ref:"preview",staticClass:"preview",class:{
                    'flex-row-reverse': _vm.form.selectedSide === 1,
                    round30x30: _vm.isRounded
                },style:(_vm.previewStyle),attrs:{"id":"preview"}},[(_vm.form.allowedFigure)?_c('div',{staticClass:"icon"},[(_vm.sampleUrl)?_c('img',{style:(_vm.iconHeight),attrs:{"src":_vm.sampleUrl}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"text",style:(_vm.textStyle[0])},[_c('div',{style:(_vm.textStyle[1]),attrs:{"id":"text1"}},[_vm._v(" "+_vm._s(_vm.model.text[0])+" ")]),(parseInt(_vm.compSelectedSticker.rows) > 1)?_c('div',{style:(_vm.textStyle[2]),attrs:{"id":"text2"}},[_vm._v(" "+_vm._s(_vm.model.text[1])+" ")]):_vm._e(),(parseInt(_vm.compSelectedSticker.rows) > 2)?_c('div',{style:(_vm.textStyle[3]),attrs:{"id":"text3"}},[_vm._v(" "+_vm._s(_vm.model.text[2])+" ")]):_vm._e()])])]):_vm._e(),(
                parseInt(_vm.compSelectedSticker.background) === 1 ||
                    parseInt(_vm.compSelectedSticker.figure) === 1
            )?_c('div',{staticClass:"white-box"},[(
                    _vm.compSelectedSticker &&
                        parseInt(_vm.compSelectedSticker.figure) === 1
                )?_c('div',{staticClass:"form-check form-switch form-control-lg py-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.allowedFigure),expression:"form.allowedFigure"}],staticClass:"form-check-input",attrs:{"type":"checkbox","value":"1","id":"switchFigure"},domProps:{"checked":Array.isArray(_vm.form.allowedFigure)?_vm._i(_vm.form.allowedFigure,"1")>-1:(_vm.form.allowedFigure)},on:{"click":_vm.alloweFigure,"change":function($event){var $$a=_vm.form.allowedFigure,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="1",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "allowedFigure", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "allowedFigure", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "allowedFigure", $$c)}}}}),_c('label',{staticClass:"form-check-label form-control-sm",attrs:{"for":"switchFigure"}},[_vm._v(_vm._s(_vm.$t("Minta")))])]):_vm._e(),(
                    _vm.compSelectedSticker &&
                        parseInt(_vm.compSelectedSticker.background) === 1
                )?_c('div',{staticClass:"form-check form-switch form-control-lg py-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.allowedBackground),expression:"form.allowedBackground"}],staticClass:"form-check-input",attrs:{"type":"checkbox","id":"switchBg","value":"1"},domProps:{"checked":Array.isArray(_vm.form.allowedBackground)?_vm._i(_vm.form.allowedBackground,"1")>-1:(_vm.form.allowedBackground)},on:{"change":[function($event){var $$a=_vm.form.allowedBackground,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="1",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "allowedBackground", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "allowedBackground", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "allowedBackground", $$c)}},_vm.setWhiteBg]}}),_c('label',{staticClass:"form-check-label form-control-sm",attrs:{"for":"switchBg"}},[_vm._v(_vm._s(_vm.$t("Háttér")))])]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }