<template>
    <div class="creator">
        <section class="hero light-bg">
            <div class="container" id="creator">
                <div class="row align-items-center">
                    <div class="col-xl-6">
                        <div class="h1">
                            {{ $t("Tervezz, ragassz, légy egyedi!") }}
                        </div>
                        <p>
                            {{
                                $t(
                                    "Névmatrica ruhákhoz és tárgyakhoz. Mosható, vasalható. Jó tapadás."
                                )
                            }}
                        </p>
                        <p>
                            {{
                                $t(
                                    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis."
                                )
                            }}
                        </p>
                        <p>
                            {{
                                $t(
                                    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis."
                                )
                            }}
                        </p>
                    </div>
                    <div class="col-xl-6">
                        <div class="row">
                            <div class="col-12">
                                <img src="/img/14.png" class="w-100 mb-4" />
                            </div>
                            <div class="col-6">
                                <img src="/img/15.png" class="w-100" />
                            </div>
                            <div class="col-6">
                                <img src="/img/16.png" class="w-100" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <LeftSide
                        :stickers="stickers"
                        :model="model"
                        :backgrounds="allBackgrounds"
                        :samples="allSamples"
                        v-if="stickers.length > 0"
                        v-on:updateValues="updateLeftSide"
                        v-on:setHeight="setHeight"
                        v-on:setWidth="setWidth"
                    />
                    <div class="col-lg-6 col-xl-8" v-if="selectedSticker">
                        <b-tabs content-class="bordered-tabs" class="pt-5" fill>
                            <b-tab :title="$t('Szöveg')">
                                <TabText
                                    v-if="fonts.length > 0"
                                    :sticker="selectedSticker"
                                    :colors="colors"
                                    :fonts="fonts"
                                    v-on:updateValues="updateText"
                                />
                            </b-tab>
                            <b-tab
                                :title="$t('Minták')"
                                v-if="
                                    parseInt(selectedSticker.figure) === 1 &&
                                        this.model.allowedFigure
                                "
                            >
                                <TabSample
                                    :sticker="selectedSticker"
                                    :samples="samples"
                                    v-on:updateValues="updateSample"
                                    :height="height"
                                    :width="width"
                                    v-on:imageCropped="sampleImageCropped"
                                />
                            </b-tab>
                            <b-tab :title="$t('Hátterek')">
                                <TabBackground
                                    :allowedBackground="
                                        parseInt(selectedSticker.background) ===
                                            1 && this.model.allowedBackground
                                    "
                                    :sticker="selectedSticker"
                                    :backgrounds="backgrounds"
                                    :colors="colors"
                                    :height="height"
                                    :width="width"
                                    v-on:imageCropped="backgroundImageCropped"
                                    v-on:updateValues="updateBackground"
                                />
                            </b-tab>
                            <b-tab
                                :title="$t('Csomag választó')"
                                v-if="parseInt(selectedSticker.package) === 1"
                            >
                                <TabPackage />
                            </b-tab>
                        </b-tabs>
                        <DownSide
                            :model="model"
                            :stickers="stickers"
                            v-if="stickers.length > 0"
                            v-on:updateValues="updateQuantity"
                        />
                        <div class="text-end">
                            <strong id="sum">{{ total }} Ft</strong>
                        </div>
                        <div class="d-grid d-xl-block text-end">
                            <button
                                class="btn btn-primary my-4"
                                v-on:click="addToCart"
                            >
                                {{ $t("Kosárba teszem") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="bg2">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <b-tabs
                            content-class="bordered-tabs"
                            fill
                            v-if="selectedSticker"
                        >
                            <b-tab :title="$t('Termékinformáció')" class="py-5">
                                <TabProductInfo :sticker="selectedSticker" />
                            </b-tab>
                            <b-tab :title="$t('GY.I.K. / Videó')" class="py-5">
                                <TabFaq :sticker="selectedSticker" />
                            </b-tab>
                            <b-tab :title="$t('Vélemények')" class="py-5">
                                <TabReviews :sticker="selectedSticker" />
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-0">
            <div class="row">
                <div class="col-4">
                    <img src="/img/17.png" class="w-100" />
                </div>
                <div class="col-4">
                    <img src="/img/18.png" class="w-100" />
                </div>
                <div class="col-4">
                    <img src="/img/19.png" class="w-100" />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import LeftSide from "../components/creator/LeftSide";
import TabText from "../components/creator/tabs/TabText";
import TabSample from "../components/creator/tabs/TabSample";
import TabBackground from "../components/creator/tabs/TabBackground";
import TabPackage from "../components/creator/tabs/TabPackage";
import TabProductInfo from "../components/creator/product-tabs/TabProductInfo";
import TabFaq from "../components/creator/product-tabs/TabFaq";
import TabReviews from "../components/creator/product-tabs/TabReviews";
import DownSide from "../components/creator/DownSide";
export default {
    name: "Creator",
    data() {
        return {
            model: {
                sticker: null,
                allowedFigure: true,
                allowedBackground: true,
                selectedSide: 0,
                textColor: null,
                text: [null, null, null],
                selectedFont: null,
                sample: null,
                customFileSample: null,
                customFileBackground: null,
                background: null,
                backgroundColor: null,
                qty_textile: 0,
                qty_plastic: 0
            },
            height: null,
            width: null,
            total: 0,
            backgrounds: [],
            colors: [],
            fonts: [],
            samples: [],
            stickers: [],
            allBackgrounds: [],
            allSamples: []
        };
    },
    computed: {
        url: function() {
            return process.env.NODE_ENV === "production"
                ? `${process.env.VUE_APP_API_URL}`
                : "";
        },
        selectedSticker: function() {
            return this.stickers.find(
                element => element.sku === this.model.sticker
            );
        }
    },
    beforeMount() {
        this.getBaseData(true);
    },
    methods: {
        addToCart() {
            const self = this;
            const uuid = localStorage.getItem("cart_uuid");
            if (self.model.qty_textile > 0 || self.model.qty_plastic > 0) {
                self.$http
                    .put(`${self.url}/cart/${uuid}`, self.model)
                    .then(response => {
                        localStorage.setItem(
                            "cart_uuid",
                            response.data.data.uuid
                        );
                        self.model.qty_plastic = 0;
                        self.model.qty_textile = 0;
                        self.$eventHub.$emit("updateCart", true);
                    });
            }
        },
        sampleImageCropped(payload) {
            this.model.customFileSample = payload;
        },
        backgroundImageCropped(payload) {
            this.model.customFileBackground = payload;
        },
        setHeight(payload) {
            this.height = payload;
        },
        setWidth(payload) {
            this.width = payload;
        },
        setDemoData() {
            const firstFont = this.fonts[0];
            const firstSample = this.allSamples[0];
            const firstBgColor = this.colors[11];
            const firstTextColor = this.colors[4];

            this.model.backgroundColor = firstBgColor.hexa;
            this.model.textColor = firstTextColor.hexa;
            this.model.selectedFont = firstFont.name;
            this.model.sample = firstSample.id;
            this.model.text[0] = this.$translate.t("Minta");
        },
        updateLeftSide(payload) {
            this.model.sticker = payload.selectedSticker;
            this.model.allowedFigure = payload.allowedFigure;
            this.model.allowedBackground = payload.allowedBackground;
            this.model.selectedSide = payload.selectedSide;
        },
        updateText(payload) {
            this.model.text = payload.text;
            this.model.selectedFont = payload.selectedFont;
            this.model.textColor = payload.textColor;
        },
        updateSample(payload) {
            this.model.sample = payload.sample;
            this.model.customFileSample = payload.customFile;
        },
        updateBackground(payload) {
            this.model.background = payload.background;
            this.model.customFileBackground = payload.customFile;
            this.model.backgroundColor = payload.backgroundColor;
        },
        updateQuantity(payload) {
            this.model.qty_textile = payload.qty_textile;
            this.model.qty_plastic = payload.qty_plastic;
            this.total =
                parseInt(this.model.qty_textile) *
                    this.selectedSticker.price_textile +
                parseInt(this.model.qty_plastic) *
                    this.selectedSticker.price_plastic;
        },
        getBaseData(first = false) {
            const self = this;
            self.$http
                .get(`${self.url}/sticker/get-data`)
                .then(response => {
                    console.log(response.data);
                    self.backgrounds = response.data.backgrounds;
                    self.colors = response.data.colors;
                    self.fonts = response.data.fonts;
                    self.samples = response.data.samples;
                    self.stickers = response.data.stickers;
                    self.allBackgrounds = response.data.all_backgrounds;
                    self.allSamples = response.data.all_samples;
                })
                .then(() => {
                    if (first) {
                        self.setDemoData();
                    }
                });
        }
    },
    components: {
        DownSide,
        TabReviews,
        TabFaq,
        TabProductInfo,
        TabPackage,
        TabBackground,
        TabSample,
        TabText,
        LeftSide
    }
};
</script>

<style scoped></style>
