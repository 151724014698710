<template>
    <div class="row">
        <div class="col-xl-6">
            <div class="creator-title mt-0">{{ $t("Írd be a szöveget") }}:</div>

            <div id="sticker-text">
                <div
                    class="form-floating mb-2"
                    v-for="(item, index) in parseInt(sticker.rows)"
                    v-bind:key="'rows-' + index"
                    :id="'sticker-text' + index"
                >
                    <div class="input-status">
                        {{ inpTextLength(form.text[index]) }}
                    </div>
                    <input
                        type="text"
                        v-model="form.text[index]"
                        class="form-control mb-2"
                        :id="'text-' + index"
                        :placeholder="
                            $t('Szöveg') + ' ' + (index + 1) + $t('. sor')
                        "
                        maxlength="18"
                    />
                    <label :for="'text-' + index">{{
                        $t("Szöveg") + " " + (index + 1) + $t(". sor")
                    }}</label>
                </div>
            </div>
        </div>
        <div class="col-xl-6">
            <div class="creator-title mt-xl-0">
                {{ $t("Válassz betűszínt") }}:
            </div>
            <ul class="color-rounds" id="colorSwitcher">
                <li
                    v-for="(item, index) in colors"
                    v-bind:key="'text-color-' + index"
                >
                    <input
                        type="radio"
                        name="icon"
                        :id="'color-' + item.id"
                        v-model="form.textColor"
                        :value="item.hexa"
                    />
                    <label :for="'color-' + item.id" class="d-block">
                        <div
                            class="round"
                            :data-color="item.hexa"
                            :style="'background-color: ' + item.hexa"
                        ></div>
                    </label>
                </li>
            </ul>
        </div>
        <div class="col-xl-12">
            <div class="creator-title">{{ $t("Válassz betűtípust") }}:</div>

            <ul class="item-list" id="fontFamily">
                <li
                    :style="'font-family:' + item.name"
                    v-for="(item, index) in fonts"
                    v-bind:key="'fonts-' + index"
                >
                    <input
                        type="radio"
                        name="font"
                        :id="'fonts-' + index"
                        v-model="form.selectedFont"
                        :value="item.name"
                    />
                    <label :for="'fonts-' + index">
                        <span>{{ item.name }}</span>
                    </label>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "TabText",
    props: ["sticker", "colors", "fonts"],
    data() {
        return {
            form: {
                text: [],
                textColor: null,
                selectedFont: null
            }
        };
    },
    watch: {
        form: {
            handler: function(val) {
                this.$emit("updateValues", val);
            },
            deep: true
        },
        sticker: {
            handler: function(val) {
                this.form.text = [];
                for (const it in parseInt(val.rows)) {
                    this.form.text[it] = null;
                }
            },
            deep: true
        }
    },
    mounted() {
        this.form.selectedFont = this.fonts[0].name;
        this.form.textColor = this.colors[4].hexa;
    },
    computed: {
        inpTextLength: function() {
            return element => {
                if (element) {
                    return element.length + " / 18";
                } else {
                    return "0 / 18";
                }
            };
        }
    }
};
</script>

<style scoped></style>
