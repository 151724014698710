<template>
    <div class="col-lg-6 col-xl-4">
        <div class="sticky-top pt-5">
            <h1 class="h4">
                <strong>{{ $t("Matricatervező") }}</strong>
            </h1>
            <div class="form-floating mb-2">
                <select
                    class="form-select"
                    id="sticker_size"
                    data-currency="Ft"
                    v-model="form.selectedSticker"
                    aria-label="Floating label select example"
                >
                    <option
                        v-for="(item, index) in stickers"
                        v-bind:key="'sticker-' + index"
                        :value="item.sku"
                        :data-price-textile="item.price_textile"
                        :data-price-plastic="item.price_plastic"
                        >{{ item.name }}</option
                    >
                </select>
                <label for="sticker_size">{{
                    $t("Válassz matrica típust")
                }}</label>
            </div>
            <div
                class="preview-holder my-4"
                v-if="
                    parseInt(compSelectedSticker.background) === 1 ||
                        parseInt(compSelectedSticker.figure) === 1
                "
            >
                <div
                    class="preview"
                    id="preview"
                    ref="preview"
                    v-bind:class="{
                        'flex-row-reverse': form.selectedSide === 1,
                        round30x30: isRounded
                    }"
                    v-bind:style="previewStyle"
                >
                    <div class="icon" v-if="form.allowedFigure">
                        <img
                            :src="sampleUrl"
                            v-if="sampleUrl"
                            v-bind:style="iconHeight"
                        />
                    </div>
                    <div class="text" :style="textStyle[0]">
                        <div id="text1" :style="textStyle[1]">
                            {{ model.text[0] }}
                        </div>
                        <div
                            id="text2"
                            v-if="parseInt(compSelectedSticker.rows) > 1"
                            :style="textStyle[2]"
                        >
                            {{ model.text[1] }}
                        </div>
                        <div
                            id="text3"
                            v-if="parseInt(compSelectedSticker.rows) > 2"
                            :style="textStyle[3]"
                        >
                            {{ model.text[2] }}
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="white-box"
                v-if="
                    parseInt(compSelectedSticker.background) === 1 ||
                        parseInt(compSelectedSticker.figure) === 1
                "
            >
                <div
                    class="form-check form-switch form-control-lg py-0"
                    v-if="
                        compSelectedSticker &&
                            parseInt(compSelectedSticker.figure) === 1
                    "
                >
                    <input
                        class="form-check-input"
                        type="checkbox"
                        value="1"
                        id="switchFigure"
                        v-model="form.allowedFigure"
                        @click="alloweFigure"
                    />
                    <label
                        class="form-check-label form-control-sm"
                        for="switchFigure"
                        >{{ $t("Minta") }}</label
                    >
                </div>
                <div
                    class="form-check form-switch form-control-lg py-0"
                    v-if="
                        compSelectedSticker &&
                            parseInt(compSelectedSticker.background) === 1
                    "
                >
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="switchBg"
                        value="1"
                        v-model="form.allowedBackground"
                        v-on:change="setWhiteBg"
                    />
                    <label
                        class="form-check-label form-control-sm"
                        for="switchBg"
                        >{{ $t("Háttér") }}</label
                    >
                </div>

                <!--
                <div
                    class="form-left-right-switcher"
                    v-if="
                        compSelectedSticker &&
                            parseInt(compSelectedSticker.figure) === 1 &&
                            form.allowedFigure
                    "
                >
                    <div
                        v-on:click="normalRow()"
                        class="btn-arrow btn-arrow-left"
                        v-bind:class="{ active: form.selectedSide === 0 }"
                    >
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="chevron-left"
                            class="svg-inline--fa fa-chevron-left fa-w-10"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                        >
                            <path
                                fill="currentColor"
                                d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                            ></path>
                        </svg>
                    </div>
                    <div
                        v-on:click="reverseRow()"
                        class="btn-arrow btn-arrow-right"
                        v-bind:class="{ active: form.selectedSide === 1 }"
                    >
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="chevron-right"
                            class="svg-inline--fa fa-chevron-right fa-w-10"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                        >
                            <path
                                fill="currentColor"
                                d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                            ></path>
                        </svg>
                    </div>

                    <label
                        class="form-check-label form-control-sm"
                        for="swithBg"
                        >{{ $t("Minta bal / jobb") }}</label
                    >
                </div>
                -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LeftSide",
    props: ["stickers", "model", "backgrounds", "samples"],
    data() {
        return {
            form: {
                selectedSticker: null,
                allowedFigure: true,
                allowedBackground: true,
                selectedSide: 0
            },
            previewPositon: null,
            windowWidth: null,
            height: null,
            width: null,
            baseFontSize: null,
            imgHeight: null
        };
    },
    watch: {
        model: {
            handler: function(val) {
                if (val.sticker) {
                    this.form.selectedSticker = val.sticker;
                } else {
                    this.form.selectedSticker = this.stickers[0].sku;
                }

                const helper = this.compSelectedSticker.height.split("/");

                this.height = Math.floor(
                    this.width /
                        (parseFloat(helper[0].trim()) /
                            parseFloat(helper[1].trim()))
                );
                this.imgHeight = parseFloat(
                    this.compSelectedSticker.img_height
                );
                this.baseFontSize = parseFloat(
                    this.compSelectedSticker.base_font_size
                );
            },
            deep: true
        },
        form: {
            handler: function(val) {
                this.$emit("updateValues", val);
            },
            deep: true
        },
        height: function(val) {
            this.$emit("setHeight", val);
        },
        width: function(val) {
            this.$emit("setWidth", val);
        }
    },
    computed: {
        isRounded() {
            if (this.compSelectedSticker.sku.includes("round")) {
                return true;
            }
            return false;
        },
        compSelectedSticker() {
            const st = this.stickers.find(
                element => element.sku === this.form.selectedSticker
            );

            if (parseInt(st.figure) === 0) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.form.allowedFigure = false;
            } else {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.form.allowedFigure = true;
            }

            return st;
        },
        previewStyle() {
            let style = {};
            if (this.model.background && this.form.allowedBackground) {
                const bg = this.backgrounds.find(
                    element => element.id === this.model.background
                );
                style["background-image"] = "url(" + bg.image + ")";
                style["background-color"] = "#fff";
            }
            if (this.model.backgroundColor) {
                style["background-color"] = this.model.backgroundColor;
                style["background-image"] = "none";
            }
            if (this.model.customFileBackground) {
                style["background-image"] =
                    "url(" + this.model.customFileBackground + ")";
                style["background-color"] = "#fff";
            }

            style["height"] = this.height + "px";

            return style;
        },
        textStyle() {
            let style = {
                0: {},
                1: {},
                2: {},
                3: {}
            };
            if (this.model.selectedFont) {
                style[0]["font-family"] = this.model.selectedFont;
                style[1]["font-family"] = this.model.selectedFont;
                style[2]["font-family"] = this.model.selectedFont;
                style[3]["font-family"] = this.model.selectedFont;
            }
            if (this.model.textColor) {
                style[0]["color"] = this.model.textColor;
                style[1]["color"] = this.model.textColor;
                style[2]["color"] = this.model.textColor;
                style[3]["color"] = this.model.textColor;
            }
            const baseFontSize = Math.floor(
                this.height / parseInt(this.baseFontSize)
            );
            const t1 = this.model.text[0] ? this.model.text[0].length : 0;
            const t2 = this.model.text[1] ? this.model.text[1].length : 0;
            const t3 = this.model.text[2] ? this.model.text[2].length : 0;

            let fontSize = [];
            fontSize[0] = baseFontSize - 8;
            fontSize[1] = baseFontSize - 8;
            fontSize[2] = baseFontSize - 8;
            fontSize[3] = baseFontSize - 8;

            if (t1 > 7) {
                if ((this.height * 2.5) / t1 < baseFontSize - 8) {
                    fontSize[1] = (this.height * 2.5) / t1;
                }
            }
            if (t2 > 7) {
                if ((this.height * 2.5) / t2 < baseFontSize - 8) {
                    fontSize[2] = (this.height * 2.5) / t2;
                }
            }
            if (t3 > 7) {
                if ((this.height * 2.5) / t3 < baseFontSize - 8) {
                    fontSize[3] = (this.height * 2.5) / t3;
                }
            }

            style[0]["font-size"] = fontSize[0] + "px";
            style[1]["font-size"] = fontSize[1] + "px";
            style[2]["font-size"] = fontSize[2] + "px";
            style[3]["font-size"] = fontSize[3] + "px";

            return style;
        },
        sampleUrl: function() {
            if (this.model.customFileSample) {
                return this.model.customFileSample;
            }
            if (this.model.sample) {
                const help = this.samples.find(
                    element => element.id === this.model.sample
                );
                return help.image;
            }

            return false;
        },
        iconHeight: function() {
            return {
                height: Math.floor(this.height * this.imgHeight) + "px"
            };
        }
    },
    beforeMount() {
        if (this.$route.query && this.$route.query.type) {
            this.model.sticker = this.$route.query.type;
        }
        if (this.model.sticker) {
            this.form.selectedSticker = this.model.sticker;
        } else {
            this.form.selectedSticker = this.stickers[0].sku;
        }
    },
    mounted() {
        const self = this;
        self.previewPositon = $(".preview-holder").offset().top;
        self.windowWidth = $(window).width();
        $(window).resize(function() {
            self.previewPositon = $(".preview-holder").offset().top;
            self.windowWidth = $(window).width();
            self.width = $("#preview").width();
            self.stickyPreview();

            const helper = self.compSelectedSticker.height.split("/");

            self.height = Math.floor(
                self.width /
                    (parseFloat(helper[0].trim()) /
                        parseFloat(helper[1].trim()))
            );
            self.imgHeight = parseFloat(self.compSelectedSticker.img_height);
            self.baseFontSize = parseFloat(
                self.compSelectedSticker.base_font_size
            );
        });

        $(window).scroll(function() {
            self.stickyPreview();
        });

        self.width = $("#preview").width();
    },
    methods: {
        alloweFigure() {
            if (this.form.allowedFigure === true) {
                this.form.allowedFigure = false;
                this.model.allowedFigure = false;
            } else {
                this.form.allowedFigure = true;
                this.model.allowedFigure = true;
            }
        },
        setWhiteBg() {
            if (!this.form.allowedBackground) {
                this.model.background = null;
                this.model.backgroundColor = "#FFF";
            }
        },
        normalRow() {
            this.form.selectedSide = 0;
        },
        reverseRow() {
            this.form.selectedSide = 1;
        },
        stickyPreview() {
            if (this.windowWidth < 768) {
                var sticky = $(".preview"),
                    scroll = $(window).scrollTop();

                if (scroll >= this.previewPositon) {
                    $(".preview-holder").css("height", $(".preview").height());
                    sticky.addClass("fixed");
                    if (this.height >= this.width) {
                        sticky.addClass("scale");
                    }
                } else {
                    $(".preview-holder").css("height", "auto");
                    sticky.removeClass("fixed");
                    sticky.removeClass("scale");
                }
            } else {
                $(".preview").removeClass("fixed");
                $(".preview-holder").css("height", "auto");
            }
        }
    }
};
</script>

<style scoped></style>
