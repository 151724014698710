import { render, staticRenderFns } from "./TabFaq.vue?vue&type=template&id=505ae841&scoped=true&"
import script from "./TabFaq.vue?vue&type=script&lang=js&"
export * from "./TabFaq.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "505ae841",
  null
  
)

export default component.exports